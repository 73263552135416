
import { defineComponent, reactive, ref, toRefs, watch } from 'vue'
import { Toast } from 'vant'
import { useRoute } from 'vue-router'
import http from '@/http'
import API from '@/http/api'
import { debounce } from './utils'

interface treeType {
  id: number
  module: number //模块:1-使用说明,2-常见问题
  platform: number //平台: 1-web,2-Android,3-IOS,4-小程序
  project: number //项目: 1-车载智能称重平台,2-汉德装车pro,3-汉德环卫,4-随你运,5-汉德车载平台
  cateName: string //分类名称/菜单名称
  parentId: number //父级主键：顶级分类值-0
  cateLevel: number //分类等级：顶级分类等级1，子级为2，以此类推
  children: treeType[]
}

const findIdByTree = function (arr: treeType[], id: number) {
  let resArray: treeType[] = []
  for (let item of arr) {
    if (item.id === id) {
      resArray = arr
      break
    } else if (item.children.length > 0) {
      resArray = findIdByTree(item.children, id)
    }
  }
  return resArray
}

export default defineComponent({
  name: 'HelpCenter',
  setup() {
    const route = useRoute()
    console.log(route.query)
    const module = ref(-1) //模块:1-使用说明,2-常见问题
    const platform = route.query.platform
    const project = route.query.project
    const idStepArr = ref<{ id: number; title: string }[]>([])
    const navBarTitle = ref('帮助中心')
    const searchValue = ref('')
    let oldTitle = ''
    let sourceTreeList: treeType[] = []

    watch(
      searchValue,
      debounce(val => {
        fetchTreeList(val)
      }, 500)
    )

    const treeProps = reactive({
      treeList: [] as treeType[],
      webContent: '',
      isDetails: false
    })

    function handleRootClick(val: number) {
      searchValue.value = ''
      module.value = val
      navBarTitle.value = val === 1 ? '使用说明' : '常见问题'
      fetchTreeList()
    }

    function handleTreeClick(item: treeType) {
      if (item.children && item.children.length > 0) {
        idStepArr.value.push({
          id: item.id,
          title: navBarTitle.value
        })
        console.log(JSON.stringify(idStepArr.value))
        navBarTitle.value = item.cateName
        treeProps.treeList = item.children
      } else {
        handleDetail(item)
      }
    }

    function handleDetail(item: treeType) {
      const data = { cateId: item.id }
      http({
        url: API.findContentByCateIdApi,
        method: 'post',
        data
      }).then(res => {
        if (res.data.length) {
          // idStepArr.push({
          //   id: item.id,
          //   title: navBarTitle.value
          // })
          oldTitle = navBarTitle.value
          navBarTitle.value = item.cateName
          treeProps.isDetails = true
          treeProps.webContent = res.data[0].webContent
        } else {
          Toast('该目录没有编辑内容')
        }
      })
    }

    function onClickBack() {
      if (treeProps.webContent) {
        treeProps.isDetails = false
        treeProps.webContent = ''
        navBarTitle.value = oldTitle
        return
      }
      if (idStepArr.value.length > 0) {
        const curStep = idStepArr.value.pop()
        // console.log(JSON.stringify(curStep))
        if (curStep) {
          const resArray = findIdByTree(sourceTreeList, curStep.id)
          // console.log(resArray)
          navBarTitle.value = curStep.title
          treeProps.treeList = resArray
        }
      } else {
        sourceTreeList = []
        treeProps.treeList = []
        module.value = -1
        navBarTitle.value = '帮助中心'
      }
    }

    function fetchTreeList(val?: string) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      let data = { module: module.value, platform, project, isMobile: '1' }
      if (val) {
        data = Object.assign(data, { searchKey: val })
      }
      http({
        url: API.findCategoryListByTreeApi,
        method: 'post',
        data
      }).then(res => {
        Toast.clear()
        sourceTreeList = res.data
        treeProps.treeList = res.data
      })
    }

    return {
      searchValue,
      module,
      navBarTitle,
      idStepArr,
      handleRootClick,
      handleTreeClick,
      onClickBack,
      ...toRefs(treeProps)
    }
  }
})
